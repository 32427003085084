//fetch the list of all events for a given UID
export function toggleCertVisiblity(cert_id, cert_visible) {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await fetch('https://htmeb6cv85.execute-api.ap-south-1.amazonaws.com/default/toggleCertVisibility', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cert_id, cert_visible }),
        });
        //console.log(res);
        let string = await res.text();
        const json = string === '' ? {} : JSON.parse(string);
        resolve(json);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
  