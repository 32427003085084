//fetch the list of all events for a given UID

export function fetchCertDetails(cert_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = 'https://htmeb6cv85.execute-api.ap-south-1.amazonaws.com/default/getCertificateInfo?id=' + cert_id;
        let res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(res);
        let string = await res.text();
        const json = string === '' ? {} : JSON.parse(string);
        resolve(json);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }