import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';

class CreateNew extends Component {
  state = {
    progressValue: 20,
    currentStep: 1,
  };

  render() {
    return (
      <React.Fragment>
        <div class="jumbotron">
          <div class="container offset-md-11 col-md-6">
            <a class="btn btn-info btn-md" href="/dashboard" role="button">
              Home
            </a>
          </div>
          <div class="container">
            <h1 class="display-3">Add a new event</h1>
            <p>Contact talktous@certifi.network for help</p>
            <hr />
          </div>
        </div>
        <div className="row">
          <div class="offset-md-4 col-md-4">
            <ProgressBar animated now={this.state.progressValue} />
          </div>
        </div>

        {this.state && this.state.currentStep === 1 && (
          <div className="row">
            <div class="offset-md-2 col-md-8">
              <br />
              <br />
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Name of Issuing Organisation</Form.Label>
                  <Form.Control type="text" placeholder="Enter name" />
                  <Form.Text className="text-muted">Provide full legal name</Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEventName">
                  <Form.Label>Event Name</Form.Label>
                  <Form.Control type="text" placeholder="Event name" />
                </Form.Group>

                <Form.Group controlId="formBasicEventDate">
                  <Form.Label>Event Date</Form.Label>
                  <Form.Control type="date" placeholder="Event date" />
                </Form.Group>

                <Form.Group controlId="formBasicText">
                  <Form.Label>Remarks/Notes</Form.Label>
                  <Form.Control type="text" placeholder="remarks" />
                </Form.Group>
                <br />
                <button type="button" class="btn btn-info" onClick={() => this.setState({ currentStep: 2, progressValue: 50 })}>
                  Proceed to step 2
                </button>
              </Form>
            </div>
          </div>
        )}

        {this.state && this.state.currentStep === 2 && (
          <div className="row">
            <div class="offset-md-2 col-md-8">
              <br />
              <br />
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Upload participant List</Form.Label>
                  <Form.Control type="file" placeholder="Select File" />
                  <Form.Text className="text-muted">Upload xlsx/csv file for best results</Form.Text>
                </Form.Group>

                <br />
                <button type="button" class="btn btn-info" onClick={() => this.setState({ currentStep: 3, progressValue: 70 })}>
                  Proceed to step 3
                </button>
              </Form>
            </div>
          </div>
        )}

        {this.state && this.state.currentStep === 3 && (
          <div className="row">
            <div class="offset-md-2 col-md-8">
              <br />
              <br />
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Upload certificate template</Form.Label>
                  <Form.Control type="file" placeholder="Select File" />
                  <Form.Text className="text-muted">Upload pdf file for best results</Form.Text>
                </Form.Group>

                <br />
                <button type="button" class="btn btn-info" onClick={() => this.setState({ currentStep: 3, progressValue: 70 })}>
                  Review
                </button>
              </Form>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CreateNew;
