import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class EventCard extends Component {
  state = {};

  handleClick = () => {
    this.props.history.push({
      pathname: '/event',
      state: { event_id: this.props.event.event_id }
    });
  }

  render() {
    const { event } = this.props;
    return (
      <div className="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{event.event_name}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{event.count} certificates</h6>
            <p class="card-text">Issued on 20Jan2020.</p>
            <button type="button" class="btn btn-info" onClick={() => this.handleClick()}>
              Manage
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EventCard);
