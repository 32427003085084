//aws login code
import { Auth } from "aws-amplify";
const awsConfig = require("./awsConfig/awsConfig.json")

// init amplify with config
Auth.configure(awsConfig);

export async function login(email, password) {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password).then(response => {
      //console.log(response);
      resolve(response);
    }).catch(e => { console.log(e); reject(e);})
  }) 
}

export function logout() {
    Auth.signOut();
  }
  
export function getCurrentUser() { 
  return new Promise(async (resolve, reject) => {
    try 
    {
      const isAuth = await Auth.currentSession();
      resolve(isAuth)
    }
    catch(error){
    reject(false)
    }
  })
}

export function getUserToken(){
  return Auth.currentSession(credentials => {
    return credentials;
  })
}
  

  export default {
    login,
    logout,
    getCurrentUser,
    getUserToken,
};