//fetch the list of all events for a given UID

export function fetchEventsForUID(uid) {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await fetch('https://htmeb6cv85.execute-api.ap-south-1.amazonaws.com/default/getEventsForUid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });
      //console.log(res);
      let string = await res.text();
      const json = string === '' ? {} : JSON.parse(string);
      resolve(json);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
