import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { getUserToken } from '../services/loginService.js';
import EventCard from './assets/EventCard';
import { logout } from '../services/loginService.js';
import { fetchEventsForUID } from '../services/fetchEventList.js';
class Dashboard extends Component {
  state = {
    events: [],
    total_certificates: 0,
    total_events: 0,
    showLoader: true,
  };

  handleLogout = async () => {
    await logout();
    window.location = '/login';
  };

  async componentDidMount() {
    try {
      let user_data = await getUserToken();
      let res = await fetchEventsForUID(user_data.getAccessToken().payload.sub);
      let total_certificates = 0;
      let total_events = res.Items.length;
      res.Items.forEach((event) => {
        if ('count' in event) {
          total_certificates += parseInt(event.count);
        }
      });
      this.setState({ events: res.Items, total_certificates, total_events, showLoader: false });
    } catch (error) {
      console.log(error);
      this.setState({ showLoader : false });
      toast.error('Unable to connect to server!');
    }
  }

  render() {
    return (
      <React.Fragment>
        <div class="jumbotron">
          <div class="container offset-md-11 col-md-6">
            <button type="button" class="btn btn-info" onClick={() => this.handleLogout()}>
              Logout
            </button>
          </div>
          <div class="container">
            <h1 class="display-3">Dashboard</h1>
            <p>Create a new event or manage previously created events.</p>
            <p>Certificates Issued : {this.state.total_certificates}</p>
            <p>Events Created : {this.state.total_events}</p>
            <hr />
            {this.state && this.state.showLoader && (
              <div className="col-md-6">
                <Loader type="ThreeDots" color="#3f51b5" />
              </div>
            )}
            <p>
              <a class="btn btn-primary btn-lg" href="/create_event" role="button">
                Create New &raquo;
              </a>
            </p>
          </div>
        </div>

        <div className="row">{this.state && !this.state.showLoader && this.state.events.map((event) => <EventCard event={event} />)}</div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
