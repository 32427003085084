import React, { useState } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import auth from './services/loginService';
import Login from './components/Login';
import Dashboard from './components/Dashboard'
import CreateNew from './components/CreateNew'
import EventPage from './components/EventPage'
import CertificatePage from './components/CertificatePage'

const ProtectedRoute = ({ status, path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log(status)
        if (!status)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

function App() {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  auth.getCurrentUser()
    .then(data => {
      setIsAuthenticated(true)
      setLoading(false)
      //console.log(data)
      return data;
    })
    .catch(err => 
      {console.log(err);
      setIsAuthenticated(false)
      setLoading(false)
    })

    if(loading) {
      //console.log('Loading')
      return <p style={{color: 'red'}}>Loading</p>
    }

  return (
    <BrowserRouter>
        <main className="container">
          <ToastContainer />
          <Switch>
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/" exact component={Dashboard} status={isAuthenticated}/>
            <ProtectedRoute path="/dashboard" exact component={Dashboard} status={isAuthenticated}/>
            <ProtectedRoute path="/create_event" exact component={CreateNew} status={isAuthenticated}/>
            <ProtectedRoute path="/event" exact component={EventPage} status={isAuthenticated}/>
            <ProtectedRoute path="/cert" exact component={CertificatePage} status={isAuthenticated} />
            <Redirect to="/404"/>
          </Switch>
        </main>
    </BrowserRouter>
  );
}

export default App;
