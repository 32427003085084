import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { fetchEventDetails } from '../services/fetchEventData.js';
class EventPage extends Component {
  state = {
    data : {},
    showLoader : true
  };

  navigateToCertificatePage = (cert_id) => {
    this.props.history.push({
      pathname: '/cert',
      state: { cert_id }
    });
  }

  async componentDidMount() {
    try {
      let data = await fetchEventDetails(this.props.location.state.event_id);
      console.log(data);
      this.setState({ data : data.Item, showLoader : false });
    } catch (error) {
      console.log(error);
      this.setState({ showLoader : false });
      toast.error('Unable to connect to server!');
    }
  }

  render() {
    const { event_id } = this.props;
    return (
      <React.Fragment>
        <div class="jumbotron">
          <div class="container offset-md-11 col-md-6">
            <a class="btn btn-info btn-md" href="/dashboard" role="button">
              Home
            </a>
          </div>
          <div class="container">
            <h1 class="display-3">{this.state.data.event_name}</h1>
            {this.state && this.state.showLoader && (
              <div className="col-md-6">
                <Loader type="ThreeDots" color="#3f51b5" />
              </div>
            )}
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h4>Event Id : {this.state.data.event_id}</h4>
            <h4>Event Date : </h4>
            <h4>Certificates Issued : {this.state.data.data ? this.state.data.data.length : '0'} </h4>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            <h4>Certificates : </h4>
            <br />
            <div class="card">
              <ul class="list-group list-group-flush">
                {this.state && this.state.data.data && (
                  this.state.data.data.map(cert => {
                    const link = "https://certifi.network/verify.html?id="+ cert;
                    // return(<li class="list-group-item"><a href={link} target = "_blank" 
                    // rel = "noopener noreferrer">{cert}</a></li>)
                    return(
                    <button class="btn btn-link btn-md" onClick={() => this.navigateToCertificatePage(cert)}>
                    {cert}
                  </button>)
                  })
                )}
                
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(EventPage);
