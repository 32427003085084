import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { fetchCertDetails } from '../services/fetchCertDetails.js';
import {toggleCertVisiblity} from '../services/toggleVisibilityService.js';

class CertificatePage extends Component {
  state = {
    data: {},
    showLoader: true,
  };

  getStatusBadge = (isVisible) => {
    return isVisible ? <span class="badge badge-success">Visible</span> : <span class="badge badge-secondary">Hidden</span>;
  };

  toggleVisibility = async (cert_id, isVisible) => {
    try{
      this.setState({showLoader : true});
      await toggleCertVisiblity(cert_id, isVisible);
      toast.success("Status Updated!");
      this.setState({showLoader : false});
      window.location.reload();
    }
    catch(err){
      toast.error("Failed to update!")
      this.setState({showLoader : false});
    }
  }

  getStatusToggleButton = (isVisible) => {
    const buttonText = isVisible ? "Hide Certificate" : "Enable Certificate";
    const buttonType = isVisible ? "btn btn-dark btn-sm" : "btn btn-info btn-sm";
    return (<button class={buttonType} onClick={() => this.toggleVisibility(this.state.data.uniqueId, !this.state.data.visible)}>{buttonText}</button>)
  }

  getCertificatePageLink = (cert_id) => {
    return 'https://certifi.network/verify.html?id=' + cert_id;
  };

  async componentDidMount() {
    try {
      console.log(this.props.location.state.cert_id);
      let data = await fetchCertDetails(this.props.location.state.cert_id);
      console.log(data);
      this.setState({ data: data.data, showLoader: false });
    } catch (error) {
      console.log(error);
      this.setState({ showLoader: false });
      toast.error('Unable to connect to server!');
    }
  }
  render() {
    return (
      <React.Fragment>
        <div class="jumbotron">
          <div class="container offset-md-11 col-md-6">
            <button class="btn btn-info btn-md" onClick={this.props.history.goBack} role="button">
              Back
            </button>
          </div>
          <div class="container">
            <h4 class="display-3">{this.state.data.event}</h4>
            {this.state && this.state.showLoader && (
              <div className="col-md-6">
                <Loader type="ThreeDots" color="#3f51b5" />
              </div>
            )}
            <hr />
          </div>
        </div>
        <div className="row">
          {this.state && !this.state.showLoader && (
            <div className="col-md-6">
              <p>Status : {this.getStatusBadge(this.state.data.visible)} {this.getStatusToggleButton(this.state.data.visible)}</p>
              <p>Email : {this.state.data.email}</p>
              <p>Name : {this.state.data.name}</p>
              <p>Unique Id : {this.state.data.uniqueId}</p>
              <p>Event Date : {this.state.data.event_date} </p>
              <p>Hash : {this.state.data.hash} </p>
              <p>
                <a href={this.getCertificatePageLink(this.state.data.uniqueId)} target="_blank" rel="noopener noreferrer">
                  View Certificate
                </a>
              </p>
            </div>
          )}
        </div>
        <hr />
      </React.Fragment>
    );
  }
}

export default CertificatePage;
