import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { login, } from "../services/loginService.js";
import { toast } from "react-toastify";
//import './login.css';
class Login extends Component {
  state = {
    email: '',
    password: '',
    showLoader: false,
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({showLoader : true});
    try{
      await login(this.state.email, this.state.password);
      toast.success("Login Success");
      window.location = "/dashboard";
    }
    catch(err){
      console.log("ERROR : ",err);
      toast.error("Login failed!")
    }
    finally{
      this.setState({showLoader : false});
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container col-md-4 col-offset-4 vertical-center">
        <form className="form-signin" onSubmit={this.handleSubmit}>
          <h1>Certifi Network</h1>
          <br />
          <h1 className="h3 mb-6 font-weight-normal">Login</h1>

          <br />
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input type="email" id="inputEmail" className="form-control" 
          onChange={e => this.setState({ email : e.target.value})}
          placeholder="Email address" required autoFocus />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input type="password" id="inputPassword" className="form-control" 
          onChange={e => this.setState({ password : e.target.value})} placeholder="Password" required />

          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Login
          </button>
          {this.state && this.state.showLoader && (
            <div className="col-md-6">
              <Loader type="ThreeDots" color="#3f51b5" />
            </div>
          )}
          <p className="mt-5 mb-3 text-muted">&copy; 2020-2021</p>
        </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
